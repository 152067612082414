// export const STRIPE_PUBLISHABLE_KEY= 'pk_test_uZVsw9TwforU9QkSkCw0X8Jj006UejsBNp' //test
export const STRIPE_PUBLISHABLE_KEY= 'pk_live_igbvnAhlPI2jMaPKPsQK0p0c00tHU8kF1I' // prod

export const languages= {
    "en": "English",
    "ar": "Arabic",
    "fr": "French",
    "de": "German",
    "it": "Italian",
    "es": "Spanish",
    "ur": "Urdu",
    "ru": "Russian",
    "ca": "Catalan",
    "cs": "Czech",
    "da": "Danish",
    "el": "Greek",
    "fa": "Persian",
    "fi": "Finnish",
    "he": "Hebrew",
    "hi": "Hindi",
    "hu": "Hungarian",
    "ja": "Japanese",
    "ko": "Korean",
    "ml": "Malayalam",
    "nl": "Dutch",
    "no": "Norwegian",
    "nn": "Norwegian Nynorsk",
    "pl": "Polish",
    "pt": "Portuguese",
    "te": "Telugu",
    "tr": "Turkish",
    "uk": "Ukrainian",
    "uz": "Uzbek",
    "vi": "Vietnamese",
    "zh": "Chinese"
}

const otherLangs = [
    "sv", "id", "ms", "ro", "ta", "th", "hr", "bg", "lt", "la", "mi", "cy", "sk", "lv", "bn", 
    "sr", "az", "sl", "kn", "et", "mk", "br", "eu", "is", "hy", "ne", "mn", "bs", "kk", "sq", 
    "sw", "gl", "mr", "pa", "si", "km", "sn", "yo", "so", "af", "oc", "ka", "be", "tg", "sd", 
    "gu", "am", "yi", "lo", "uz", "fo", "ht", "ps", "tk", "mt", "sa", "lb", "my", "bo", "tl", 
    "mg", "as", "tt", "haw", "ln", "ha", "ba", "jw", "su", "yue"
]